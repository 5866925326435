function Organization(id, baseContainer, description) {

    /**
     * Constructors
     */
    this.id = id;
    this.baseContainer = baseContainer;
    this.description = description;
    this.configurations = [];

    /**
     * Internal Functions
     */
    /**
     * Returns the index of the given configuration in the configurations array;
     *
     * @param id            : the identifier of the configuration to find.
     * @returns {Integer}    : the index of the configuration in the organization based on
     *          the id parameter. Returns -1 if not found.
     */
    this.indexOf = function (id) {


        for (var i = 0; i < this.configurations.length; i++) {
            if (this.configurations[i].id == id) {
                return i;
            }
        }
        /* If no index has been found on id, try to find it on aliasId */
        for (var j = 0; j < this.configurations.length; j++) {

            if (this.configurations[j].aliasId) {
                var aliasId = this.configurations[j].aliasId.split(";");
                for (var k = 0; k < this.configurations[j].aliasId.split(";").length; k++) {
                    if (this.configurations[j].aliasId.split(";")[k] == id) {
                        return j;
                    }
                    o
                }
                if (this.configurations[j].aliasId.split(";").indexOf(id) != -1) {
                    return j;
                }
            }
        }
        return -1;
    };

    /**
     * Add or update an configuration to the organization based on the id in the configuration
     *
     * @param {Object} configuration        : configuration object to add or modify
     * @return TRUE                    : always returns TRUE
     */
    this.addConfiguration = function (configuration) {
        if (this.indexOf(configuration.id) !== -1) {
            this.configurations.splice(this.indexOf(configuration.id), 1);
        }
        this.configurations.push(configuration);
        return true;
    };

    /**
     * Get the base container of the given type
     *
     * @param {String} type, type of identity
     * @return {String} Destinquished Name of the Active Container
     */
    this.getBaseContainerByType = function (type) {
        if (typeof type != 'undefined' && this.configurations[this.indexOf(type)].getBaseContainer()) {
            return this.configurations[this.indexOf(type)].getBaseContainer();
        }
        return this.baseContainer;
    };
    /**
     * Get the active container of the given type
     *
     * @param {String} type, type of identity
     * @return {String} Destinquished Name of the Active Container
     */
    this.getActiveContainer = function (type) {
        if (this.indexOf(type) !== -1) {
            return this.configurations[this.indexOf(type)].getActiveRDN() + ',' + this.getBaseContainerByType(type);
        }
        return -1;
    };

    /**
     * Get the inactive container of the given type
     *
     * @param {String} type, type of identity
     * @return {String} Destinquished Name of the Inactive Container
     */
    this.getInactiveContainer = function (type) {
        if (this.indexOf(type) !== -1) {
            return this.configurations[this.indexOf(type)].getInactiveRDN() + ',' + this.getBaseContainerByType(type);
        }
        return -1;
    };

    this.getGraceBefore = function (type) {
        if (this.indexOf(type) !== -1) {
            return this.configurations[this.indexOf(type)].graceBefore;
        }
        return '0';
    };

    this.getGraceAfter = function (type) {
        if (this.indexOf(type) !== -1) {
            return this.configurations[this.indexOf(type)].graceAfter;
        }
        return '0';
    };

    this.getPasswordFormat = function (type) {
        if (this.indexOf(type) !== -1) {
            return this.configurations[this.indexOf(type)].passwordFormat;
        }
        return '-1';
    };

    this.getCnFormat = function (type) {
        if (this.indexOf(type) !== -1) {
            return this.configurations[this.indexOf(type)].cnFormat;
        }
        return -1;
    };

    this.getCnFormatOption = function (type) {
        if (this.indexOf(type) !== -1) {
            return this.configurations[this.indexOf(type)].cnFormatOption;
        }
        return -1;
    };

    /**
     * Get the Group Container of the organization
     *
     * @return {String} Destinquished Name of the Groups Container
     */
    this.getGroupContainer = function () {
        return (this.groupContainer) ? this.groupContainer : "OU=Automatic,OU=Groups," + this.baseContainer;
    };

    /**
     * Get the Metadata Container of the organization
     *
     * @return {String} Destinquished Name of the Metadata Container
     */
    this.getMetadataContainer = function () {
        return (this.metadataContainer) ? this.metadataContainer : "OU=Metadata,OU=System," + this.baseContainer;
    };

    /**
     * Show all the configured types
     *
     * @return {String} comma-seperated list of all configured types
     */
    this.getConfigurationTypes = function () {
        configTypes = [];
        for (i = 0; i < this.configurations.length; i++) {
            configTypes.push(this.configurations[i].id);
        }
        return configTypes;
    };

    /**
     * Returns the engagement as a human readable string.
     * @returns {String} human readable engagement
     */
    this.toString = function () {
        return jsonStringify(this);
    };

}

function Configuration(id,
                       cnFormat, // attribute|idprovider|custom
                       cnFormatOption,  // AttributeName | idproviderpolicy|null
                       passwordFormat,
                       graceBefore,
                       graceAfter,
                       notifyTo,
                       notifyToAttribute,
                       notifyCC,
                       notifyCCAttribute,
                       notifyBCC,
                       notifyBCCAttribute
) {
    /**
     * Constant Variables
     */
    containerTypeId = "OU=";
    rdnActiveContainer = "OU=active,OU=identities";
    rdnInactiveContainer = "OU=inactive,OU=identities";

    /**
     *
     * Constructors
     *
     */

    /* Mandatory */
    this.id = id;
    this.passwordFormat = passwordFormat;
    this.graceBefore = graceBefore;
    this.graceAfter = graceAfter;
    this.cnFormat = cnFormat;

    /* Optional */
    this.cnFormatOption = (typeof(cnFormatOption) === 'undefined') ? '' : cnFormatOption;
    this.notifyTo = (typeof(notifyTo) === 'undefined') ? '' : notifyTo;
    this.notifyToAttribute = (typeof(notifyToAttribute) === 'undefined') ? '' : notifyToAttribute;
    this.notifyCC = (typeof(notifyCC) === 'undefined') ? '' : notifyCC;
    this.notifyCCAttribute = (typeof(notifyCCAttribute) === 'undefined') ? '' : notifyCCAttribute;
    this.notifyBCC = (typeof(notifyBCC) === 'undefined') ? '' : notifyBCC;
    this.notifyBCCAttribute = (typeof(notifyBCCAttribute) === 'undefined') ? '' : notifyBCCAttribute;

    /**
     *
     * Internal Functions
     *
     */
    /**
     * Returns the relative DN of the identity type
     */
    this.getTypeRDN = function () {
        return containerTypeId + this.id + 's';
    };
    /**
     * Returns the DN of the active container
     */
    this.getActiveRDN = function () {
        return this.getTypeRDN() + ',' + rdnActiveContainer;
    };
    /**
     * Returns the DN of the inactive container
     */
    this.getInactiveRDN = function () {
        return this.getTypeRDN() + ',' + rdnInactiveContainer;
    };
    this.getBaseContainer = function () {
        return this.baseContainer ? this.baseContainer : false;
    };
    this.getAliasId = function () {
        return this.aliasId ? this.aliasId : false;
    };
    /**
     * Setters
     */
    this.setId = function (id) {
        this.id = id;
    };
    this.setAliasId = function (aliasId) {
        this.aliasId = aliasId;
    };
    this.setBaseContainer = function (baseContainer) {
        this.baseContainer = baseContainer;
    };
    this.setPasswordFormat = function (passwordFormat) {
        this.passwordFormat = passwordFormat;
    };
    this.setNotifyTo = function (notify) {
        this.notifyTo = notify;
    };
    this.setNotifyToAttribute = function (notifyAttribute) {
        this.notifyToAttribute = notifyAttribute;
    };
    this.setNotifyCC = function (notify) {
        this.notifyCC = notify;
    };
    this.setNotifyCCAttribute = function (notifyAttribute) {
        this.notifyCCAttribute = notifyAttribute;
    };
    this.setNotifyBCC = function (notify) {
        this.notifyCC = notify;
    };
    this.setNotifyBCCAttribute = function (notifyAttribute) {
        this.notifyBCCAttribute = notifyAttribute;
    };

    /**
     * Returns the engagement as a human readable string.
     * @returns {String} human readable engagement
     */
    this.toString = function () {
        return jsonStringify(this);
    };
}

/* Functions */

/**
 * Restores a given json string to an organization object.
 *
 * @param {String} jsonConfiguration
 * @return {Object} Configuration object.
 */
function orgRestore(jsonConfig) {
    if (jsonConfig == '' || typeof(jsonConfig) === 'undefined') {
        return {};
    }
    var body = jsonParse(jsonConfig);
    //return body
    var org = new Organization(body.id,
        body.baseContainer,
        body.description);
    if (body.configurations) {
        for (var i = 0; i < body.configurations.length; i++) {
            org.addConfiguration(configurationRestore(jsonStringify(body.configurations[i])));
        }
    }
    return org;
}

/**
 * Adds one configuration to the given organization. Returns the organization including the
 * added or updated configuration.
 *
 * @param {String} jsonOrganization
 * @param {String} jsonConfiguration
 * @returns {String} the updated organization in JSON format.
 */
function orgAddConfiguration(jsonOrganization, jsonConfiguration) {
    var organization = orgRestore(jsonOrganization);
    organization.addConfiguration(configurationRestore(jsonConfiguration));
    return jsonStringify(organization);
}

/**
 * Get the active container for a specific type of identity from the organization
 *
 * @param {String} jsonorganization
 * @param {String} type (Type of identity)
 * @param {Boolean} ldap (return in LDAP format)
 * @return {String} the DN of the active container
 */
function orgGetContainerActive(jsonOrganization, type, ldap) {
    var organization = orgRestore(jsonOrganization);
    return (ldap) ? organization.getActiveContainer(type) : ldapToSlash(organization.getActiveContainer(type));
}

/**
 * Get the inactive container for a specific type of identity from the organization
 *
 * @param {String} jsonorganization
 * @param {String} type (Type of identity)
 * @param {Boolean} ldap (return in LDAP format)
 * @return {String} the DN of the active container
 */
function orgGetContainerInactive(jsonOrganization, type, ldap) {
    var organization = orgRestore(jsonOrganization);
    return (ldap) ? organization.getInactiveContainer(type) : ldapToSlash(organization.getInactiveContainer(type));
}

/*
 * Get all the containers where the useraccount can be stored (e.g. Active and inactive container)
 *
 * @param {String} jsonOrganization
 * @param {String} type (Type of identity)
 * @param {Boolean} ldap (return in LDAP format)
 * @return {String} comma-seperated placement containers
 */
function orgGetContainerPlacement(jsonOrganization, type, ldap) {
    var placement = [];
    placement.push(orgGetContainerActive(jsonOrganization, type, ldap));
    placement.push(orgGetContainerInactive(jsonOrganization, type, ldap));
    return placement.join(';');
}

function orgGetTypeGraceBefore(jsonOrganization, type, override) {
    if (override && override != '-1' && override != '') {
        return override.toString();
    }
    if (typeof(type) === 'undefined' || typeof(jsonOrganization) === 'undefined') {
        return '0';
    }
    var organization = orgRestore(jsonOrganization);
    return organization.getGraceBefore(type);
}

function orgGetTypeGraceAfter(jsonOrganization, type, override) {
    if (override && override != '-1' && override != '') {
        return override.toString();
    }
    if (typeof(type) === 'undefined' || typeof(jsonOrganization) === 'undefined') {
        return '0';
    }
    var organization = orgRestore(jsonOrganization);
    return organization.getGraceAfter(type);
}


function orgGetTypeCnFormat(jsonOrganization, type) {

    if (typeof(type) === 'undefined' || typeof(jsonOrganization) === 'undefined') {
        return '0';
    }
    var organization = orgRestore(jsonOrganization);
    return organization.getCnFormat(type);
}

function orgGetTypeCnFormatOption(jsonOrganization, type) {

    if (typeof(type) === 'undefined' || typeof(jsonOrganization) === 'undefined') {
        return '0';
    }
    var organization = orgRestore(jsonOrganization);
    return organization.getCnFormatOption(type);
}

/*
 * Get the group container where the groups for IDM will be stored
 *
 * @param {String} jsonOrganization
 * @param {Boolean} ldap (return in LDAP format)
 * @return {String} the DN of the Group Container
 */
function orgGetContainerGroup(jsonOrganization, ldap) {
    var organization = orgRestore(jsonOrganization);
    return (ldap) ? organization.getGroupContainer() : ldapToSlash(organization.getGroupContainer());
}

/*
 * Get the metadata container where the metadata objects for IDM will be stored
 *
 * @param {String} jsonOrganization
 * @param {Boolean} ldap (return in LDAP format)
 * @return {String} the DN of the Metadata Container
 */
function orgGetContainerMetadata(jsonOrganization, ldap) {
    var organization = orgRestore(jsonOrganization);
    return (ldap) ? organization.getMetadataContainer() : ldapToSlash(organization.getMetadataContainer());
}

/**
 * Get a comma-separated list with all configured account types within the organization
 *
 * @param {String} jsonOrganization
 * @return {String} a comma-seperated list with account types
 */
function orgGetConfigurationTypes(jsonOrganization) {
    var organization = orgRestore(jsonOrganization);
    return organization.getConfigurationTypes().join(';');
}

function orgConfigurationTypeExist(jsonOrganization, type) {
    var organization = new Organization();
    if (jsonOrganization > '') {
        organization = orgRestore(jsonOrganization);
    }
    if (organization.indexOf(type) != -1) {
        return true;
    }
    return false;
}

/**
 * Restores the configuration JSON to an Configuration() object.
 *
 * @param {String} jsonConfiguration
 * @return {Object} the restored configuration object
 */
function configurationRestore(jsonConfiguration) {
    var body = jsonParse(jsonConfiguration);
    var configuration = new Configuration(body.id,
        body.cnFormat,
        body.cnFormatOption,
        body.passwordFormat,
        body.graceBefore,
        body.graceAfter,
        body.notifyTo,
        body.notifyToAttribute,
        body.notifyCC,
        body.notifyCCAttribute,
        body.notifyBCC,
        body.notifyBCCAttribute);
    if (body.baseContainer) {
        configuration.setBaseContainer(body.baseContainer);
    }
    if (body.aliasId) {
        configuration.setAliasId(body.aliasId);
    }
    return configuration;
}

function orgConfPassword(jsonOrganization, type) {
    function genPassword(lamount, uamount, namount, samount, mix) {
        var lamount = (typeof lamount === 'undefined') ? 0 : lamount;
        var uamount = (typeof uamount === 'undefined') ? 0 : uamount;
        var namount = (typeof namount === 'undefined') ? 0 : namount;
        var samount = (typeof samount === 'undefined') ? 0 : samount;
        var mix = (typeof mix === 'undefined') ? 0 : mix;
        var lowercaseChars = "abcdefghijkmnopqrstuvwxyz";
        var uppercaseChars = "ABCDEFGHJKLMNPQRSTUVWXYZ";
        var numberChars = "23456789";
        var specialChars = '!@#$%*()-_+=';
        var outputPassword = "";
        for (var i = 0; i < lamount; i++) outputPassword += uppercaseChars.substring(Math.random() * uppercaseChars.length).substring(0, 1);
        for (var i = 0; i < uamount; i++) outputPassword += lowercaseChars.substring(Math.random() * lowercaseChars.length).substring(0, 1);
        for (var i = 0; i < namount; i++) outputPassword += numberChars.substring(Math.random() * numberChars.length).substring(0, 1);
        for (var i = 0; i < samount; i++) outputPassword += specialChars.substring(Math.random() * numberChars.length).substring(0, 1);
        return (mix === 1) ? outputPassword = outputPassword.split('').sort(function () {
            return 0.5 - Math.random()
        }).join('') : outputPassword;
    }

    var passwordOptions = "2;3;2;1;0";
    var organization = new Organization();

    if (jsonOrganization) {
        organization = orgRestore(jsonOrganization);
    }
    if (organization.indexOf(type) != -1) {
        passwordOptions = organization.getPasswordFormat(type);
    }
    passwordOptions = passwordOptions.split(';');
    return genPassword(passwordOptions[0], passwordOptions[1], passwordOptions[2], passwordOptions[3], passwordOptions[4]);
}

/**
 * Returns a LDAP-DN in unqualified-slash format
 *
 * @param {String} ldapDN
 * @return {String} unqualified-slash DN
 */
function ldapToSlash(ldapDN) {
    if (ldapDN.search(/=/) != -1) {
        var rdns = ldapDN.split(',');
        var slashDN = '';
        for (var i = 0; i < rdns.length; i++) {
            name = rdns[i].split('=');
            slashDN = (slashDN == '') ? name[1] : name[1] + '\\' + slashDN;
        }
        return slashDN;
    }
}

/**
 * Return a XML-envelope based on object
 *
 * @param {object} inputObject
 * @return {String} XML-Envelope
 */
function objectToXML(inputObject) {
    resultXML = '';
    for (i = 0; i < inputObject.length; i++) {
        if (typeof(inputObject[i]) === 'object') {
            resultXML = resultXML + objectToXML(inputObject[i]);
        }
        else {
            resultXML = resultXML + '<' + typeof(inputObject[i]) + '>' + inputObject[i] + '</' + typeof(inputObject[i]) + '>\r\n';
        }
    }
    return '<' + typeof(inputObject) + '>\r\n' + resultXML + '</' + typeof(inputObject) + '>\r\n';
}

/**
 * Convert a comma-serarated string to XML
 *
 * @param {String} inputCSV
 * @param {String} separator : character to separate the inputCSV
 * @return {String} XML-Envelope
 */
function csvToXML(inputCSV, separator) {
    if (typeof(seperator) === 'undefined') {
        separator = ';';
    }
    return objectToXML(inputCSV.split(separator));
}


/**
 * First checks if JSON is availble and then passes on the stringify operation
 * to this object. If it does not find JSON it will first check if it's running
 * in the userapplication and use the built in. If it does not find that it will
 * assume getJson is available. The EcmaScript policy "JSON" needs to be added
 * to drivers as well for this to work
 *
 * @param {Object} object
 * @param {Number} format indicator 1 is string, 2 is pretty
 * @return {String} the object as a valid Json string.
 */
function jsonStringify(item, format) {
    if (typeof (JSON2) !== 'object') {

        /* Are we inside a browser? */
        if (typeof JSON == 'object') {
            JSON2 = JSON;

            /* Are we inside the userapp backend */
        } else if (typeof ScriptVault != 'undefined') {

            JSON2 = ScriptVault.JSON;
        } else {

            /* Meh, let's use our own */
            loadJson();
        }
    }
    return JSON2.stringify(item, null, format);
}


/**
 * First checks if JSON is availble and then passes on the parse operation to
 * this object. If it does not find JSON it will first check if it's running in
 * the userapplication and use the built in. If it does not find that it will
 * assume getJson is available. The EcmaScript policy "JSON" needs to be added
 * to drivers as well for this to work
 *
 * @param {String} string the object as a valid Json string.
 * @return {Object} object
 */
function jsonParse(string) {
    if (typeof (JSON2) !== 'object') {

        /* Are we inside a browser? */
        if (typeof JSON == 'object') {
            JSON2 = JSON;

            /* Are we inside the userapp backend */
        } else if (typeof ScriptVault != 'undefined') {
            JSON2 = ScriptVault.JSON;

            /* Meh, let's use our own */
        } else {
            loadJson();
        }
    }
    return JSON2.parse(string);
}

function testOrgConf(returnTestBox) {
    var testBox = ["Testing orgConfigLibrary"];
    var orgString = '{"id":"00","description":"Main Organization","baseContainer":"ou=org00,o=resources"}';
    var orgObject = orgRestore(orgString);
    //return true;
    if (typeof orgObject == 'object') {
        testBox.push(' - Organization created');
    }
    else {
        return "OrgRestore failed for: " + orgString;
    }
    configString = '{"id":"employee","cnFormat":"custom","graceBefore":"7","graceAfter":"14","baseContainer":"ou=org99,o=resources","aliasId":"external"}';
    if (orgObject.addConfiguration(configurationRestore(configString))) {
        testBox.push(' - Configuration "employee" added');
    }
    else {
        return " - addConfiguration 'employee' Failed";
    }
    if (orgObject.configurations[0].getBaseContainer()) {
        testBox.push(' - BaseContainer Override "employee" is set to: ' + orgGetContainerActive(jsonStringify(orgObject), 'employee'));
    }
    else {
        return "BaseContainer 'employee' FAILED";
    }
    if (orgGetTypeGraceBefore(jsonStringify(orgObject), 'employee', '-1') == 7 &&
        orgGetTypeGraceBefore(jsonStringify(orgObject), 'employee', '') == 7 &&
        orgGetTypeGraceBefore(jsonStringify(orgObject), 'employee') == 7) {
        testBox.push(' - Grace Before "employee" is 7 without override');
    }
    else {
        return "GraceBefore 'employee' failed to return value '7'";
    }
    if (orgGetTypeGraceBefore(jsonStringify(orgObject), 'employee', '30') == 30) {
        testBox.push(' - Grace Before "employee" is 30 with override');
    }
    else {
        return "GraceBefore 'employee' failed to return the override value '30'";
    }
    if (orgGetTypeGraceBefore(jsonStringify(orgObject), 'employee', '0') == 0) {
        testBox.push(' - Grace Before "employee" is 0 with override');
    }
    else {
        return "GraceBefore 'employee' failed to return the override value '0'";
    }
    if (orgConfigurationTypeExist(jsonStringify(orgObject), 'external')) {
        testBox.push(' - aliasId external will also be placed in: ' + orgGetContainerActive(jsonStringify(orgObject), 'external'));
    }
    else {
        return "Finding configuration in employee config based on aliasId with 'external' failed";
    }
    if (orgConfigurationTypeExist(jsonStringify(orgObject), 'interim')) {
        return "Finding 'interim' as aliasId incorrectly returned a placement of: " + orgGetContainerActive(jsonStringify(orgObject), 'interim');
    }
    else {
        testBox.push(' - aliasId interim is correctly not found');
    }
    configString = '{"id":"student","cnFormat":"custom","graceBefore":"7","graceAfter":"14"}';
    if (orgObject.addConfiguration(configurationRestore(configString))) {
        testBox.push(' - Configuration "student" added');
    }
    else {
        return "addConfiguration 'student' Failed, string used: " + configString;
    }
    if (orgObject.configurations[1].getBaseContainer()) {
        return "getBaseContainer 'student' FAILED";
    }
    else {
        testBox.push(' - BaseContainer "student" is default: ' + orgGetContainerActive(jsonStringify(orgObject), 'student'));
    }
    testBox.push(" - Group Container: " + orgGetContainerGroup(orgObject.toString()));
    testBox.push(" - Metadata Container: " + orgGetContainerMetadata(orgObject.toString()));
    testBox.push("DONE TESTING, JSON result: " + jsonStringify(orgObject));
    if (!returnTestBox) {
        return 0;
    }
    return testBox.join('\n');
}