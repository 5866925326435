// variables
var _collections = '#eduarte_onderwijs_product_courses';

$(window).on("load", function () {

    // Settings for exclusions, categories and fields
    // Get the collection div
    var collectionHolder = $(_collections);

    collectionHolder.each(function () {
        var self = $(this);

        // add a delete link to all of the existing entities
        self.find("tr").each(function () {
            addEntityDeleteLink(this, self);
        });

        // add the "Add" link
        // addCollectionAddLink(self);

        // count the current form inputs we have (e.g. 2), use that as the new
        // index when inserting a new item (e.g. 2)
        self.data('index', self.find(':input').length);
    });

});

/**
 * Add form to add an entity to a collection
 * @param collectionDiv
 * @param addLink
 */
function addEntityForm(collectionDiv, addLink) {
    // Get the data-prototype explained earlier
    var prototype = collectionDiv.data('prototype');

    // get the new index
    var index = collectionDiv.data('index');

    var newForm = prototype;
    // You need this only if you didn't set 'label' => false in your tags field in TaskType
    // Replace '__name__label__' in the prototype's HTML to
    // instead be a number based on how many items we have
    // newForm = newForm.replace(/__name__label__/g, index);

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have

    var temp = document.createElement('div');
    temp.innerHTML = newForm.replace(/__name__/g, index);
    newForm = temp.firstChild;

    // increase the index with one for the next item
    collectionDiv.data('index', index + 1);

    // delete link for new entity form
    addEntityDeleteLink(newForm, collectionDiv);

    // If has collection inside, add "Add" link
    $(newForm).find(_collections).each(function () {
        addCollectionAddLink($(this));
    });

    // Display the form before the "Add" link
    addLink.before(newForm);
}

/**
 * Add link form for an collection
 * @param collection
 */
function addCollectionAddLink(collection) {

    // setup an "Add" link
    var link_data = document.createElement('div');
    link_data.innerHTML = collection.data('add');
    var addLink = link_data.firstChild;

    // add translation
    if (collection.data('trans-add') != null) {
        $(addLink).text(collection.data('trans-add'));
    }

    $(addLink).on('click', function (e) {
        e.preventDefault();
        addEntityForm(collection, $(this));
    });

    $(collection).append(addLink);
}

/**
 * Delete link form for an entity
 * @param entityForm
 * @param collection
 */
function addEntityDeleteLink(entityForm, collection) {

    // setup an "Delete" link
    var link_data = document.createElement('div');
    link_data.innerHTML = collection.data('delete');
    var removeLink = link_data.firstChild;

    // add translation
    if (collection.data('trans-delete') != null) {
        $(removeLink).attr('title', collection.data('trans-delete'));
    }

    $(removeLink).on('click', function (e) {
        e.preventDefault();

        // remove the form element
        entityForm.remove();
        $("#eduarte_onderwijs_product_submit").click();
    });

    $(entityForm).find('#js-form-delete-wrapper').last().append(removeLink);
}