/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './css/app.scss';
import * as bootstrap from 'bootstrap'

// start the Stimulus application
import './bootstrap';

/** DO NOT REMOVE, WILL BREAK JAVASCRIPT IN COMBINATION WITH BASE.HTML.TWIG POPPER/BOOTSTRAP SCRIPT */
import 'bootstrap/dist/js/bootstrap.bundle.min'
/** END WARNING*/

require('./js/correct-grades');
require('./js/eduarte_product_courses');
require('./js/organsiations');

$(window).on("load", function () {

    // Enable tooltips
    $('[data-toggle="tooltip"]').tooltip();

});

