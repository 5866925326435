import {Controller} from '@hotwired/stimulus';
import {Modal} from 'bootstrap';
import $ from 'jquery';

export default class extends Controller {
    static targets = ['modal', 'modalBody'];
    static values = {
        formUrl: String,
        modalId: String
    }
    modal = null;
    modalId = null;
    async openModal(event) {

        $.fn.modal.Constructor.prototype.enforceFocus = function () {
        };
        console.log(this.formUrlValue);
        console.log(this.modalIdValue);
        this.modal = new Modal(this.modalTarget);
        this.modal.show();
        this.modalBodyTarget.innerHTML = await $.ajax(this.formUrlValue);
        $('.select2').select2({
            theme: 'bootstrap-5',
            selectionCssClass: 'select2--small',
            dropdownParent: $('#' + this.modalIdValue)
        });

        $('.user-search').select2({
            theme: 'bootstrap-5',
            dropdownParent: $('#' + this.modalIdValue),
            ajax: {
                method: 'get',
                url: '/modiam/identities/search',
                'delay': 500,
                processResults: function (data) {
                    // Tranforms the top-level key of the response object from 'items' to 'results'
                    return {
                        results: data.results
                    };
                }
            }
        });
        $('.async-search').select2({
            theme: 'bootstrap-5',
            selectionCssClass: 'select2--small',
            dropdownCssClass: 'select2--small',
            ajax: {
                method: 'get',
                url: $(this).data('url'),
                'delay': 500,
                processResults: function (data) {
                    console.log(data);
                    console.log($(parent));
                    // Tranforms the top-level key of the response object from 'items' to 'results'
                    return {
                        results: data.results
                    };
                }
            }
        });
    }

    async submit(event) {
        event.preventDefault();
        const $form = $(this.modalBodyTarget).find('form');
        try {
            await $.ajax({
                url: this.formUrlValue,
                method: $form.prop('method'),
                data: $form.serialize(),
            });
            this.modal.hide();
            location.reload();
        } catch (e) {
            console.log(e);
            this.modalBodyTarget.innerHTML = e.responseText;
        }
    }
    modalHidden() {
        console.log('it was hidden!');
    }
}