var bootbox = require('bootbox');
import('bootstrap')
import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

$(document).ready(function () {

    var teacher_code = $(".js-grade-teacher").data("teachercode");


    $(".js-send-grades").click(function (e) {

        var grades_to_send = [];

        $(".result-item").each(function (index, item) {
            var checkbox = $(item).find('input[type="checkbox"]').get(0);
            if (checkbox.checked == true) {
                grades_to_send.push($(item).data("id"));
            }

        });

        if (grades_to_send.length > 0) {
            sendCorrectedGrades(grades_to_send);
        } else {
            bootbox.alert("Selecteer resultaten om te verzenden");
        }
    });

    $(".js-grade-fix").click(function (e) {
        console.log('clkickcluijkc')
        var self = $(this);

        var part_id = self.data('id');
        var part_item = $('.result-item[data-id=' + part_id + ']');
        console.log(part_item)
        var grade = part_item.data('grade');
        var correctionGrade = part_item.data('correction-grade');
        var correctionDescription = part_item.data('correction-description');
        var correctionDate = part_item.data('correction-date');
        var correctionBy = part_item.data('correction-by');

        bootbox.confirm({
            message: '<h2>Correctie</h2>' +
            '<form id="bootbox-fields">' +
            '<input type="hidden" name="part_id" value="' + part_id + '"/>' +
            '<label>Gecorrigeerd cijfer</label>' +
            '<input type="text" class="form-control" name="grade" value="' + correctionGrade + '"/>' +
            '<label>Beschrijving</label>' +
            '<textarea rows="4" class="form-control" name="description">' + correctionDescription + '</textarea>' +
            '<label>Docentcode</label>' +
            '<input type="text" class="form-control" readonly="readonly" name="teacher_code" value="' + teacher_code + '"/>' +
            '<label>Datum</label>' +
            '<input type="datetime-local" class="form-control" readonly="readonly" name="date" value="' + new Date().toJSON().slice(0, 19) + '"/>' +
            '</form>',
            buttons: {
                cancel: {
                    label: '<i class="fa fa-times"></i> Annuleer'
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> Corrigeer'
                }
            },
            callback: function (result) {
                if (result) {
                    // form values from array to object
                    var values = $('#bootbox-fields').serializeArray();
                    var data = {};
                    for (var i = 0; i < values.length; i++) {
                        data[values[i]['name']] = values[i]['value'];
                    }
                    saveCorrectedGrade(data);
                }
            }
        });
    });

    function saveCorrectedGrade(values) {
        var url = "/api/results/correct_grade";
        var data = {
            attempt_id: values.part_id,
            grade: values.grade,
            description: values.description,
            teacher_code: values.teacher_code,
            datetime: values.date
        };
        if (data.grade && !(data.grade.match(/^([0-9]|10)\.[0-9]$|^V$/))) {
            bootbox.alert("Alleen cijfers tussen 0.0 en 10.0 of \"V\" kunnen ingevuld worden.");
            return false;
        }
        $.post(url, data)
            .done(function (response) {
                console.log(response);

                var part_item = $('.result-item[data-id=' + values.part_id + ']');

                part_item.data('correction', true);
                part_item.data('correction-grade', values.grade);
                part_item.data('correction-description', values.description);
                part_item.data('correction-date', values.date);
                part_item.data('correction-by', values.teacher_code);

                part_item.find('.result-item__correctedgrade').html(values.grade);
                part_item.find('.result-item__status').html(part_item.find('.result-item__status').data('text-corrected'));
            })
            .fail(function (response) {
                bootbox.alert("Er is een fout opgetreden bij het corrigeren van een cijfer");
            })
            .always(function (response) {
            });
    }


    function sendCorrectedGrades(values) {
        var url = "/ansResults/send";
        var data = {part_ids: values};
        $.post(url, data)
            .done(function (response) {
                bootbox.alert("Met success verstuurd !", function () {
                    location.reload();
                });
            })
            .fail(function (response) {
                bootbox.alert("Fout bij versturen resultaten: </br> " + getErrorTable(response.responseJSON));
            })
            .always(function (response) {
            });
    }

    function getErrorTable(response) {
        console.log('response error table', response)
        let trs = '';
        $.each(response, function(key, value) {
            console.log(key, value)
            const errorText = value.error === true ? 'Fout' : 'Verstuurd';
            trs += '<tr>' +
                '<td>' + value.part + '</td>' +
                '<td>' + value.message + '</td>' +
                '<td>' + errorText + '</td>' +
                '</tr>'
        })

        return '<table class="table table-striped">' +
            '<thead>' +
            '<tr>' +
            '<th>Examen</th>'+
            '<th>Error</th>'+
            '<th>Verstuurd</th>'+
            '</tr>' +
            '</thead>' +
            '<tbody>' +
                trs +
            '</tbody>' +
            '</table>';

    }
});
